import React from "react";

const Footer: React.FC = () => {
    return (
        <footer className="bg-grey txt-black paragraphe py-6 md:flex-column">
          <div className="container mx-auto flex flex-col md:flex-column justify-between items-center">
            <div className="mb-4 md:mb-0">
              <h1 className="txt-yellow titre font-bold">YDI</h1>
              <p>&copy; {new Date().getFullYear()} Tous droits réservés.</p>
            </div>
          
          </div>
        </footer>
    );
}

export default Footer;
