import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from './button';
import logo from '../assets/mon-logo.png'

type HeaderProps = {
  scrollToAbout: () => void;
  scrollToServices: () => void;
  scrollToCommunity: () => void;
};

const Header: React.FC<HeaderProps> = ({ scrollToAbout, scrollToServices, scrollToCommunity }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header className="bg-grey p-8 fixed top-0 left-0 w-[100%] z-50 ">
      <div className="container flex justify-between items-center top-0 left-0 max-w-full">
        {/* Logo */}
        <Link to="/" onClick={closeMenu}>
          <img src={logo} alt="Logo" className="h-10 w-auto cursor-pointer max-w-120 sm:max-w-full" />
        </Link>

        <div className="container mx-8 flex flex-col justify-between items-center sm:items-end md:items-center">
  {/* Titre I DESERVE IT */}
  <h1 className='txt-yellow text-2xl sm:text-right md:text-center sm:mr-0'>I DESERVE IT</h1>



          {/* TradingView Widget - Grand écran seulement */}
          <div className="hidden md:block w-full mx-8">
            <iframe
              scrolling="no"
              allowTransparency={true}
              frameBorder="0"
              src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=fr#%7B%22symbols%22%3A%5B%7B%22description%22%3A%22DOT%2FUSDT%22%2C%22proName%22%3A%22KRAKEN%3ADOTUSDT%22%7D%2C%7B%22description%22%3A%22BTC%2FUSD%22%2C%22proName%22%3A%22COINBASE%3ABTCUSD%22%7D%2C%7B%22description%22%3A%22TAO%2FUSDT%22%2C%22proName%22%3A%22BINANCE%3ATAOUSDT%22%7D%2C%7B%22description%22%3A%22EUR%2FUSD%22%2C%22proName%22%3A%22OANDA%3AEURUSD%22%7D%2C%7B%22description%22%3A%22NASDAQ-100%22%2C%22proName%22%3A%22NASDAQ%3ANDX%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A44%2C%22utm_source%22%3A%22www.ideserveit.fr%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%7D"
              style={{ boxSizing: 'border-box', height: '45px', width: '100%' }}
            ></iframe>
          </div>
        </div>  

        {/* Bouton pour Réserver un appel - Affiché seulement sur grand écran */}
        <a href="https://calendly.com/ideserveitpro/presentation-de-nos-services" target="_blank" rel="noopener noreferrer">
          <Button label="Réserver un appel" onClick={closeMenu} className="hidden md:block" />
        </a>
      </div>

      {/* TradingView Widget - Petit écran (sous le header) */}
      <div className="md:hidden mt-4">
        <iframe
          scrolling="no"
          allowTransparency={true}
          frameBorder="0"
          src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=fr#%7B%22symbols%22%3A%5B%7B%22description%22%3A%22DOT%2FUSDT%22%2C%22proName%22%3A%22KRAKEN%3ADOTUSDT%22%7D%2C%7B%22description%22%3A%22BTC%2FUSD%22%2C%22proName%22%3A%22COINBASE%3ABTCUSD%22%7D%2C%7B%22description%22%3A%22TAO%2FUSDT%22%2C%22proName%22%3A%22BINANCE%3ATAOUSDT%22%7D%2C%7B%22description%22%3A%22EUR%2FUSD%22%2C%22proName%22%3A%22OANDA%3AEURUSD%22%7D%2C%7B%22description%22%3A%22NASDAQ-100%22%2C%22proName%22%3A%22NASDAQ%3ANDX%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A44%2C%22utm_source%22%3A%22www.ideserveit.fr%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%7D"
          style={{ boxSizing: 'border-box', height: '64px', width: '100%' }}
        ></iframe>
      </div>
    </header>
  );
};

export default Header;
