import React, { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import Button from "../components/button";
import captureEcran1 from '../assets/capture-ecran1.png';
import captureEcran2 from '../assets/capture-ecran2.png';
import captureEcran3 from '../assets/capture-ecran3.png';
import video from '../assets/video_mapmonde.mp4';
import { useInView } from "react-intersection-observer";


const Home: React.FC = () => {
    const aboutRef = useRef<HTMLDivElement>(null);
    const servicesRef = useRef<HTMLDivElement>(null);
    const communityRef = useRef<HTMLDivElement>(null);

    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

    const closeMenu = () => {
        setIsOpen(false);
      };

      const [show, setShow] = useState(false);

    // Utilisation de useInView avec un nom distinct pour éviter le conflit
    const { ref: aboutInViewRef, inView } = useInView({
        triggerOnce: true,  // Cette option garantit que l'animation ne se déclenche qu'une seule fois lorsque la section est visible
        threshold: 0.2,     // Déclenche l'animation lorsque 50% de l'élément est visible
    });


    // Mettre `show` à true lorsque l'élément est visible
    useEffect(() => {
        if (inView) {
            setShow(true);
        }
    }, [inView]);







  
        const boxRef = useRef(null);
      
        // Fonction pour animer l'incrémentation des chiffres
        function increment() {
            const incrementValues = document.querySelectorAll('.increment_value');
            incrementValues.forEach((incrementValue) => {
              let incrementValueNumber = parseInt(incrementValue.getAttribute('data-increment') || '0');
              let initValue = parseInt(incrementValue.innerHTML);
              
              let incrementIntval = setInterval(() => {
                // Convertir initValue en chaîne de caractères avant de l'assigner à innerHTML
                incrementValue.innerHTML = String(initValue); // ou initValue.toString() ou `${initValue}`
                
                if (initValue >= incrementValueNumber) {
                  clearInterval(incrementIntval);
                }
                
                initValue++;
              }, 10); // Ajoutez une petite pause pour la fluidité
            });
          }
          
      
        useEffect(() => {
          if (boxRef.current) {
            let box = boxRef.current;
            const options = {
              rootMargin: '0px',
              threshold: 0.5, // Déclenche l'animation lorsque 50% de l'élément est visible
            };
      
            const observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  increment(); // Déclencher l'animation lorsque l'élément devient visible
                  observer.unobserve(entry.target);
                }
              });
            }, options);
      
            observer.observe(box);
          }
        }, []);






      


    return (
        <div className=" overflow-x-hidden bg-grey">
            {/* Header avec des fonctions de défilement */}
            <Header
                scrollToAbout={() => scrollToSection(aboutRef)}
                scrollToServices={() => scrollToSection(servicesRef)}
                scrollToCommunity={() => scrollToSection(communityRef)}
            />

            {/* Section d'Introduction avec vidéo en arrière-plan */}
            <section className="relative min-h-screen flex flex-col items-center justify-center overflow-hidden">
                <video
                    autoPlay
                    loop
                    muted
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    onError={(e) => console.error('Erreur de chargement de la vidéo:', e)}
                >
                    <source src={video} type="video/mp4" />
                    Votre navigateur ne prend pas en charge la vidéo.
                </video>
                <div className="relative z-10 text-center flex flex-col  mb-8 element">
                    {/* Bouton ajouté ici */}
                    <a href="https://calendly.com/ideserveitpro/presentation-de-nos-services" target="_blank" rel="noopener noreferrer" >
          <Button label="Réserver un appel" onClick={closeMenu} />
        </a>
                </div>



            









                













            </section>

            {/* Sections */}
            <main className="space-y-20">




            <section
  ref={boxRef}
  className="h-[15vh] flex justify-center items-center bg-grey2"
>
  <div className="grid grid-cols-3 lg:gap-48  text-center justify-items-center">
    {/* Section 1 */}
    <div className="flex flex-col items-center">
      <h2 className="text-xl font-bold">
        +<span className="increment_value" data-increment="457">0</span>
      </h2>
      <p className="text-sm text-black">Elèves nous ont fait confiance</p>
    </div>

    {/* Section 2 */}
    <div className="flex flex-col items-center">
      <h2 className="text-xl font-bold">
        +<span className="increment_value" data-increment="98">0</span> %
      </h2>
      <p className="text-sm text-black">De nos clients sont satisfaits</p>
    </div>

    {/* Section 3 */}
    <div className="flex flex-col items-center">
      <h2 className="text-xl font-bold">
        +<span className="increment_value" data-increment="1136">0</span>
      </h2>
      <p className="text-sm text-black">Heures de vidéos de formation</p>
    </div>
  </div>
</section>









            <section ref={aboutInViewRef} className="min-h-screen flex flex-col md:flex-row items-center justify-between bg-grey p-10 w-full">
    <div className="flex flex-col items-center md:w-[45%] mb-8 md:mb-0">
        <img 
            src={captureEcran1}
            alt="Description de l'image gauche" 
            className={`w-3/4 h-auto rounded-lg shadow-lg transition-transform2 ${show ? "transform translate-x-0" : "transform translate-x-[-130%]"}`}
        />
        <p className="mt-4 text-center text-xl">
            <span className="txt-yellow">UNE RECOMPENSE</span>
            <span className="text-white"> A LA HAUTEUR DE VOTRE VALEUR</span>
        </p>
    </div>

    <div className="flex flex-col items-center md:w-[45%]">
        <img 
            src={captureEcran2}
            alt="Description de l'image droite" 
            className={`w-3/4 h-auto rounded-lg shadow-lg transition-transform2 ${show ? "transform translate-x-0" : "transform translate-x-[130%]"}`}
        />
        <p className="mt-4 text-center text-xl">
            <span className="text-white"> VOUS LE</span>
            <span className="txt-yellow"> MERITEZ</span>
            <span className="text-white"> ET TU LE MERITE</span>
            <br /><br />
        </p>
        <p className="text-white text-left text-sm">En effet YDI met un point d'honneur à ce que chacun de ses membres soit <span className="font-bold">100% gagnants</span> : sur le marché comme dans la vie.</p>
        <p className="text-white text-left text-sm">Nous croyons en qui vous êtes, et c'est en cette raison que I deserve IT a été créée.</p>
    </div>
</section>



<section
  ref={servicesRef}
  className="min-h-screen flex flex-col items-center justify-center bg-grey p-10 w-full "
>
  <h2 className="text-2xl text-white text-center mb-2">NOS SERVICES</h2>
  <p className="text-white text-sm mt-2  mb-24">
        Une diversité de services pour répondre à toutes vos attentes.
      </p>
  <div className="flex flex-col md:flex-row w-full justify-center items-center md:space-x-16 space-y-16 md:space-y-0">
    {/* Rectangle 1 */}
    <div className="w-80 h-64 degrade-jaune text-white p-6 rounded-2xl shadow-lg text-center flex flex-col justify-between">
      <h3 className="text-xl font-bold mt-4">YDI Formation</h3>
      <p className="text-s mt-2 leading-7">
        Nous mettons nos compétences pluridisciplinaires à votre service pour
        vous permettre de comprendre, maîtriser et naviguer en toute sérénité au
        sein de l'univers des marchés financiers, avec plus de 150h de vidéos
        de formation et un accompagnement personnalisé, des rapports etc.
      </p>
      <a href="https://calendly.com/ideserveitpro/presentation-de-nos-services" target="_blank" rel="noopener noreferrer" >
          <Button label="En savoir plus" onClick={closeMenu} />
        </a>
    </div>
    {/* Rectangle 2 */}
    <div className="w-80 h-64 degrade-rouge text-white p-6 rounded-2xl shadow-lg text-center flex flex-col justify-between">
      <h3 className="text-xl font-bold mt-4">YDI Outils</h3>
      <p className="text-s mt-2 leading-7">
        Nos outils permettent au trader d'améliorer sa discipline et ajoutent un
        soutien à la gestion des émotions sur ses prises de décisions. Gagnez
        plus de temps dès aujourd'hui grâce à nos outils de trading !
      </p>
      <a href="https://calendly.com/ideserveitpro/presentation-de-nos-services" target="_blank" rel="noopener noreferrer" >
          <Button label="En savoir plus" onClick={closeMenu} />
        </a>
    </div>
    {/* Rectangle 3 */}
    <div className="w-80 h-64 degrade-violet text-white p-6 rounded-2xl shadow-lg text-center flex flex-col justify-between">
      <h3 className="text-xl font-bold mt-4">YDI Founding</h3>
      <p className="text-s mt-2 leading-7">
        Nous vous offrons la possibilité de faire l'acquisition d'un compte réel
        financé jusqu'à 25 000 euros. Nous avons conçu un programme adapté à la
        plupart des types de traders. Nous sommes là pour initialiser, maintenir
        et développer votre longue carrière de trading.
      </p>
      <a href="https://calendly.com/ideserveitpro/presentation-de-nos-services" target="_blank" rel="noopener noreferrer" >
          <Button label="En savoir plus" onClick={closeMenu} />
        </a>
    </div>
  </div>
</section>





<section ref={communityRef} className="min-h-screen flex flex-col items-center justify-center bg-grey p-10 w-full mt-0">
    <h2 className="text-2xl text-white text-center mb-16">NOTRE COMMUNAUTÉ</h2>
    <div className="w-full max-w-4xl relative overflow-hidden" style={{ aspectRatio: '16 / 9' }}> {/* Utilisation de aspectRatio */}
        <iframe 
            className="absolute inset-0 w-full h-full" // Positionner en absolu pour remplir le conteneur
            src="https://www.youtube.com/embed/9CyfBZn1ZEU?si=ZVceH3rAwv8WUVi1" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerPolicy="strict-origin-when-cross-origin" 
            allowFullScreen
        ></iframe>
    </div>
</section>












<h2 className="text-2xl text-white text-center mb-32">UNE QUESTION, UNE RÉPONSE</h2>
            <section className="min-h-screen flex flex-col md:flex-row items-center justify-center bg-grey p-10 w-full mt-0">

    <div className="flex flex-col items-center md:w-1/2 md:mb-4 mb-32 ">
        <img 
            src={captureEcran3}
            alt="Description de l'image gauche" 
            className={`w-3/4 h-auto rounded-lg shadow-lg transition-transform2 ${show ? "transform translate-x-0" : "transform translate-x-[-130%]"}`}
        />
        
    </div>


        <div className="md:w-1/2">
        <p className=" text-center text-xl">
            
            <p className="text-white text-left text-sm font-bold ">Est-ce que je peux résilier mon abonnement à tout moment ?</p>
            <br />
            <p className="text-white text-left text-sm ">Oui, le paiement est mensuel, avant chaque début de mois vous pouvez résilier votre abonnement.</p>
            <br />
            <br />
            <p className="text-white text-left text-sm font-bold ">Qu'est ce qui affecte le prix des devises ?</p>
            <br />
            <p className="text-white text-left text-sm">Les prix des devises (taux de change) sont influencés par diverses conditions économiques et politiques, notamment les taux d'intérêt, l'inflation et la stabilité politique. De plus, les gouvernements participent parfois au marché des changes pour influencer la valeur de leur monnaie, soit en inondant le marché avec leur monnaie nationale pour tenter de faire baisser le prix, soit à l'inverse en achetant pour augmenter le prix. C'est ce qu'on appelle l'intervention de la Banque centrale. Chacun de ces facteurs, ainsi que les ordres de marché importants, peuvent provoquer une forte volatilité des prix des devises. Cependant, la taille et le volume du marché des changes font qu'il est impossible pour une entité donnée de "diriger" le marché pendant une période donnée.</p>
            <br />
            <br />
            <p className="text-white text-left text-sm font-bold ">Comment gérer les risques en trading ?</p>
            <br />
            <p className="text-white text-left text-sm"><span>Le trading implique un risque élevé et ne convient pas à tous les investisseurs. Le degré élevé d’effet de levier peut opérer en votre faveur aussi bien que contre vous. Avant de décider de trader, vous devez attentivement considérer vos objectifs d’investissement, votre niveau d’expérience et votre et votre appétit du risque.</span>
            <br />
            <span>Pour cela, nous vous invitons à ne pas négliger de vous former avant d’investir des fonds dans une activité de trader en ligne indépendant. Il y a toujours une relation entre rémunération élevée et risque élevé. Tous types de marchés ou de spéculation en trading, qui peut rapporter un rendement élevé sur l’investissement, est soumis à un risque élevé. Vous ne devez donc jamais engager dans le trading des sommes d’argent dont vous pourriez en avoir besoin pour vivre. Notez toutefois que YDI ne constitue en aucun cas en un conseille en investissement, que vous êtes entièrement responsable de vos gains et pertes liées aux opérations de trading qui résulteraient de l’utilisation de ces données.</span></p>
            <br />
            <br />
        </p>
        
        </div>
 
</section>













            </main>
        </div>
    );
};

export default Home;
